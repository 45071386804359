@use 'sass:math';

.fc-avatar {
    display: block;
    position: relative;
    z-index: 100;
    outline: none;
    max-width: 16rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include noselect();

    .button {
        cursor: pointer;

        .image {
            width: 100%;
            // height: 16rem;
            height: 0;
            position: relative;
            padding-bottom: 100%;

            &::after {
                content: '';
                display: block;
            }

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 100%;
                display: block;
                object-fit: cover;
                border: 0.125rem solid $secondary;
            }

            .image-danger {
                border: 0.125rem solid $danger;
            }
        }
    }
}
