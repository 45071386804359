@import 'forms/input';
@import 'forms/dropdown';
@import 'forms/calendar';
@import 'forms/timer';
@import 'forms/checkbox';
@import 'forms/textarea';
@import 'forms/avatar';
@import 'forms/radio';

.form-control {
    position: relative;
    display: inline-block;
    max-width: 100%;

    &.form-control-block {
        width: 100%;

        textarea,
        input[type='text'],
        input[type='email'],
        input[type='password'],
        input[type='number'],
        .dropdown,
        .phone {
            width: 100%;
        }
    }
}
