.frame-table {
    margin: 0 auto;
    width: 100%;

    @each $name, $max-width in $frame-max-width {
        &.frame-table-#{$name} {
            @include frame-max-width($max-width);
        }
    }
}

.card-table {
    @include card();

    padding-top: 0;
    padding-right: 0;
    padding-left: 0;

    .search-wrapper {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }

    .table-wrapper {
        overflow: auto;
        border-top: 1px solid rgba($primary, 0.2);
        border-bottom: 1px solid rgba($primary, 0.2);
    }

    thead {
        tr {
            background-color: $gray-100;
        }
    }

    table {
        width: 100%;

        @each $name, $colors in $table-colors {
            &.table-#{$name} {
                th {
                    @include table-variant($colors);
                }
            }
        }

        th {
            font-size: $table-header-font-size;
            color: $gray-500;
            vertical-align: middle;

            &.type-sortable {
                padding-right: 1.25rem;

                a {
                    width: min-content;
                    position: relative;
                    color: $gray-500;
                    text-decoration: none;
                    white-space: normal;
                    text-align: left;

                    @include animation(color);

                    &::after,
                    &::before {
                        content: '';
                        position: absolute;
                        right: -1rem;
                        top: 50%;
                        display: block;
                        width: 0.75rem;
                        height: 0.75rem;
                        border: 0.375rem solid transparent;

                        @include animation(border-color);
                    }

                    &::after {
                        transform: translateY(calc(-50% + 0.4375rem));
                        border-top-color: $gray-300;
                    }

                    &::before {
                        transform: translateY(calc(-50% - 0.4375rem));
                        border-bottom-color: $gray-300;
                    }
                }
            }

            &.type-sorted-asc,
            &.type-sorted-desc {
                a {
                    color: $primary;
                }
            }

            &.type-sorted-asc {
                a::after {
                    border-top-color: $primary;
                }
            }

            &.type-sorted-desc {
                a::before {
                    border-bottom-color: $primary;
                }
            }
        }

        td {
            font-size: $table-font-size;
            border-top: 1px solid rgba($primary, 0.2);
        }

        th,
        td {
            padding: 0.75rem 0.5rem;

            &.type-number {
                text-align: center;
            }
        }

        :first-child > th,
        td:first-child {
            padding-left: 1.5rem;
        }

        :last-child > th,
        td:last-child {
            padding-right: 1.5rem;
        }
    }
}
