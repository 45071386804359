.card {
    @include card();

    @each $name, $max-width in $card-max-width {
        &.card-#{$name} {
            @include card-max-width($max-width);
        }
    }
}

.actions {
    margin-left: auto;
    margin-right: auto;

    &:empty {
        display: none !important;
    }
}

@each $name, $max-width in $card-max-width {
    .actions-#{$name} {
        @include card-max-width($max-width);
    }
}
