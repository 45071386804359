@use 'sass:color';

$white: #fff;
$gray-100: #f9f9f9;
$gray-200: #ebeced;
$gray-300: #d8d9db;
$gray-400: #9ea0a5;
$gray-500: #777a81;
$gray-600: #3e414b;
$gray-700: #23242b;
$black: #000;

$body-bg: color.mix($gray-200, $white, 38%);
$body-color: $gray-500;

$primary: #46449c;
$secondary: #ffd000;
$success: #71d23a;
$info: #1491ff;
$warning: #ff7b14;
$danger: #d50000;
$light: $gray-100;
$dark-400: #7c6e65;
$dark-500: #60554e;
$gray: #808080;
$gold: #ffd700;
