@use 'sass:map';

@mixin table-variant($colors) {
    $color: map.get($colors, 'default');

    background-color: $color;
    color: $white;

    a {
        /* stylelint-disable-next-line declaration-no-important */
        color: $white !important;
    }
}

@mixin frame-max-width($max-width) {
    $max-width: map.get($max-width, 'default');

    max-width: $max-width;
}
