// fonts
@import 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap';

// colors
@import 'theme/colors';
// variables
@import 'theme/variables';
// bootstrap
@import 'theme/bootstrap/starter';
// perfect-scrollbar
@import 'theme/perfect-scrollbar/perfect-scrollbar';
// theme
@import 'theme/theme';
// medium-editor
@import 'theme/tiptap/tiptap';

body,
html {
    height: 100%;
}
