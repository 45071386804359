.icon {
    display: inline-block;
    vertical-align: middle;

    @include animation(background-color);

    @each $name, $color in $theme-colors {
        &.icon-#{$name} {
            fill: $color;
        }
    }

    @each $name, $size in $icon-sizes {
        &.icon-#{$name} {
            width: $size;
            height: $size;
            min-width: $size;
            min-height: $size;
            line-height: 1;
            font-size: 0;

            @include animation(fill);

            svg {
                width: $size;
                height: $size;
                min-width: $size;
                min-height: $size;
            }
        }
    }
}
