@use 'sass:math';

.fc-calendar {
    display: inline-block;
    position: relative;
    z-index: 100;
    outline: none;
    cursor: pointer;
    width: 100%;

    &.calendar-open {
        cursor: default;
        z-index: 105;
    }

    @include noselect();

    input {
        @include form-text($input-height, $input-font-size, $input-padding-x);

        cursor: pointer;
        opacity: 1;
    }

    > label {
        @include form-label($input-height, $input-font-size, $input-padding-x);

        cursor: pointer;
    }

    @each $name, $color in $theme-colors {
        &.calendar-#{$name} {
            @include form-calendar-variant($color);
        }
    }

    &.fc-disabled {
        > label {
            @include form-label-disabled();
        }

        input {
            @include form-disabled();
        }
    }

    > .icon {
        position: absolute;
        z-index: 20;
        top: math.div(($input-height - 1.5rem), 2);
        left: 1rem;
    }

    @include media-breakpoint-up(lg) {
        z-index: 10;

        &.calendar-open {
            /* stylelint-disable-next-line declaration-no-important */
            z-index: 40 !important;
        }
    }
}

.calendar-picker {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 35;
    min-width: 100%;
    width: 20rem;
    background-color: $white;
    border: 1px solid $gray-300;
    padding-top: 3rem;

    .close {
        position: absolute;
        right: 1rem;
        top: 1rem;
    }

    .legend {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-row-gap: 1px;
        border-left: 2rem solid $white;
        border-right: 2rem solid $white;

        @include media-breakpoint-up(lg) {
            border-left: none;
            border-right: none;
        }

        div {
            text-align: center;
            font-size: 0.75rem;
            font-weight: 700;
            color: $gray-600;
        }
    }

    .days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-row-gap: 1px;
        background-color: $gray-200;
        border-left: 2rem solid $white;
        border-right: 2rem solid $white;

        @include media-breakpoint-up(lg) {
            border-left: none;
            border-right: none;
        }

        div {
            background-color: $white;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            @include media-breakpoint-up(lg) {
                padding-top: 0.125rem;
                padding-bottom: 0.125rem;
            }

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 2rem;
                height: 2rem;
                line-height: 1;
                margin-left: auto;
                margin-right: auto;
                border-radius: 100%;
                border: 1px solid transparent;
                background-color: $white;
                text-align: center;
                font-size: 0.75rem;
                color: $gray-300;
                cursor: dafault;

                @include animation(background-color, border-color, color);
            }

            &:not(.blocked) {
                span {
                    cursor: pointer;

                    &:hover {
                        border-color: $secondary;
                        background-color: $secondary;
                        color: $white;
                    }
                }

                &.current-month {
                    span {
                        color: $gray-500;
                    }
                }

                &.today {
                    span {
                        border-color: $gray-300;
                    }
                }

                &.selected {
                    span {
                        background-color: $secondary;
                        border-color: $secondary;
                        color: black;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 2rem;
        position: absolute;
        bottom: auto;

        .close {
            right: 0.5rem;
            top: 0.5rem;
        }
    }
}
