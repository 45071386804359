/* Animations */
@mixin animation($prop1, $prop2: '', $prop3: '', $prop4: '', $prop5: '') {
    $variable: $prop1 $animation-time $animation-style;

    @if $prop2 != '' {
        $variable:
            $variable,
            $prop2 $animation-time $animation-style;
    }

    @if $prop3 != '' {
        $variable:
            $variable,
            $prop3 $animation-time $animation-style;
    }

    @if $prop4 != '' {
        $variable:
            $variable,
            $prop4 $animation-time $animation-style;
    }

    @if $prop5 != '' {
        $variable:
            $variable,
            $prop5 $animation-time $animation-style;
    }

    transition: $variable;
}

@mixin bezier() {
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
    transition-delay: 0ms;
}
