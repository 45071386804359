a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &.link {
        font-weight: 700;
        font-size: 0.75rem;
        color: $gray-600;
        display: inline-block;
        position: relative;
        white-space: nowrap;

        @each $name, $color in $theme-colors {
            &.link-#{$name} {
                color: $color;
            }
        }
    }
}
