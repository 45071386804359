@import 'theme/shared';

.menu-dropdown {
    position: fixed;
    min-width: max-content;
    background-color: $menu-dropdown-background-color;
    border-radius: $border-radius;
    font-size: $menu-dropdown-font-size;
    z-index: $menu-dropdown-z-index;
    padding-top: $menu-dropdown-padding-y;
    padding-bottom: $menu-dropdown-padding-y;

    &::before {
        content: '';
        display: block;
        left: 0;
        height: 1rem;
        top: -1rem;
        width: 100%;
        background-color: transparent;
        position: absolute;
    }

    @include shadow-1();

    li {
        position: relative;
        width: 100%;

        &::before {
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            height: 100%;
            width: 0;
            background-color: $white;
            cursor: pointer;

            @include animation(width, background-color);
            @include bezier();
        }

        a {
            color: $menu-dropdown-color;
            line-height: $menu-dropdown-line-height;
            padding: $menu-dropdown-padding-y $menu-dropdown-padding-x;
            width: 100%;
            display: block;
            cursor: pointer;
            z-index: 2;
            text-decoration: none;
            position: relative;

            @include animation(color);
            @include bezier();

            @media (hover: hover) {
                &:hover {
                    color: $menu-dropdown-color-hover;
                }
            }
        }

        &:hover {
            &::before {
                width: 100%;
                background-color: $light;
            }
        }
    }

    .divider {
        height: 0;
        overflow: hidden;
        border-top: 1px solid #e9ecef;
    }
}
