.tiptap {
    outline: none;

    p {
        margin-bottom: 0;
    }

    color: $gray-600;
    font-size: 0.875rem;

    p.is-editor-empty:first-child::before {
        color: $gray-400;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
        cursor: text;
        font-size: 0.875rem;
    }
}
