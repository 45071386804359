.h-divider {
    background-color: rgba(0 0 0 / 5%);
    border-radius: 4px;
    height: 1px;
    margin: 1rem 0;
    width: 100%;
}

.h-divider2 {
    height: 5px;
}
