@each $name, $size in $font-sizes {
    .fs-#{$name} {
        font-size: $size;
    }
}

.fw-700 {
    font-weight: 700;
}

.fw-600 {
    font-weight: 600;
}

.fw-500 {
    font-weight: 500;
}

.fw-400 {
    font-weight: 400;
}

.text-none {
    font-size: 0;
    line-height: 1;
}

.text-oneline {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-break-word {
    word-wrap: break-word;
}

.text-underline {
    text-decoration: underline;
}

.font-italic {
    font-style: italic;
}

.text-secure {
    @include noselect();
}
