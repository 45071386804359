.alert {
    border: 1px solid transparent;
    padding: 0.5rem 1rem;
    margin-left: auto;
    margin-right: auto;
    background-color: $white;

    @each $name, $color in $theme-colors {
        &.alert-#{$name} {
            background-color: rgba($color, 1);
            color: $white;
            font-size: 1rem;
        }
    }
}
