@use 'sass:math';
@use 'sass:map';
@use 'sass:color';

@mixin button($height, $padding-x) {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    border-width: $button-border-width;
    border-style: solid;
    border-color: $gray-400;
    border-radius: $button-radius;
    line-height: $button-lh;
    padding: (math.div($height, 2) - math.div($button-lh, 2) - $button-border-width) $padding-x (math.div($height, 2) - math.div($button-lh, 2) - $button-border-width) $padding-x;
    font-size: $button-font-size;
    color: $button-color;
    font-weight: 700;
    letter-spacing: 0.5px;
    width: $button-width;
    max-width: 100%;
    outline: none;
    background-color: $gray-400;

    &:hover {
        color: $white;
        text-decoration: none;
    }

    @include animation(border-color, background-color);
}

@mixin button-variant($colors) {
    $color: map.get($colors, 'default');
    $hover: map.get($colors, 'dark');

    border-color: $color;
    background-color: $color;

    &:not(.btn-disabled) {
        &:hover {
            border-color: color.adjust($color, $lightness: -10%);
            background-color: color.adjust($color, $lightness: -10%);
        }
    }
}

@mixin button-outline-variant($colors) {
    $color: map.get($colors, 'default');
    $hover: map.get($colors, 'dark');

    border-color: $gray-300;
    background-color: transparent;
    color: $color;

    &:hover {
        border-color: $color;
        background-color: $color;
        color: $white;
    }
}

@mixin button-size($height, $padding-x) {
    padding: (math.div($height, 2) - math.div($button-lh, 2) - $button-border-width) $padding-x (math.div($height, 2) - math.div($button-lh, 2) - $button-border-width) $padding-x;
    min-width: $button-width-sm;
}

@mixin button-loader {
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($dark-400, 0.1);
        z-index: 10;
        opacity: 0;

        @include animation(opacity);
    }

    &::before {
        @keyframes button-animation-ring {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        content: '';
        display: block;
        top: calc(50% - 1rem);
        left: calc(50% - 1rem);
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: 0.25rem solid $white;
        border-color: $white transparent $white transparent;
        animation: button-animation-ring 0.8s linear infinite;
        position: absolute;
        z-index: 11;
        opacity: 0;

        @include animation(opacity);
    }

    &.btn-spinner {
        &::after,
        &::before {
            opacity: 1;
            cursor: default;
        }
    }
}
