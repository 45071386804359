@mixin shadow-1() {
    box-shadow: 0 0.125rem 0.1875rem 0 rgba($gray-400, 0.2);
}

@mixin shadow-2() {
    box-shadow: 0 0.125rem 0.1875rem 0 $gray-400;
}

@mixin shadow-3() {
    box-shadow: 3rem 0 1px 3.1rem $gray-200;
}
