@each $name, $color in $theme-colors {
    .bg-#{$name} {
        background-color: $color;
    }
}

@each $name, $color in $theme-colors {
    .bg-hover-#{$name} {
        &:hover {
            background-color: $color;
        }
    }
}

[class*=' bg-'] {
    @include animation(background-color);
}
