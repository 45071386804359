html {
    height: 100%;
    background-color: $white;
    font-size: 14px;

    @include media-breakpoint-up(sm) {
        font-size: 16px;
    }
}

body {
    font-family: Poppins, sans-serif;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
}
