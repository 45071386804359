@use 'sass:math';

@mixin form-choice() {
    input {
        display: none;

        + label {
            @include noselect();
            /* stylelint-disable-next-line declaration-no-important */
            line-height: 1.125rem !important;

            .icon {
                position: absolute;
                font-size: 0;

                svg {
                    fill: $white;

                    @include animation(fill);
                }
            }

            &::before {
                margin-right: 0.5rem;
                content: '';
                display: block;
                min-width: 1.125rem;
                min-height: 1.125rem;
                border-width: $input-border-width;
                border-style: solid;
                border-color: $input-border-color;

                @include animation(border-color, background-color);
            }
        }

        &:checked {
            + label {
                &::before {
                    border-color: $primary;
                    background-color: $primary;
                }
            }
        }

        &:not(.fc-disabled, :disabled) {
            + label {
                cursor: pointer;
            }
        }

        &:not(:disabled, :checked) {
            + label:hover {
                .icon {
                    svg {
                        fill: $gray-300;
                    }
                }
            }
        }
    }
}

@mixin form-choice-variant($color) {
    &.fc-filled {
        + label {
            color: $input-label-color;
        }
    }

    &:focus {
        /* stylelint-disable-next-line declaration-no-important */
        z-index: 20 !important;
        border-color: $color;

        &:not(.label-block) {
            + label {
                color: $color;
            }
        }
    }

    &.fc-touched.fc-invalid {
        /* stylelint-disable-next-line declaration-no-important */
        z-index: 11 !important;
        border-color: $danger;

        &:not(.label-block) {
            + label {
                color: $danger;
            }
        }
    }
}

@mixin form-choice-label($height, $font-size, $padding-x) {
    top: 0;
    position: absolute;
    font-size: $input-font-size;
    line-height: $input-lh;
    left: $padding-x + 0.1875rem;
    transform: translate(0, math.div($height, 2) - math.div($input-lh, 2));

    @include animation(color, transform, font-size);

    cursor: text;
    z-index: 30;

    &::before {
        content: '';
        display: block;
        position: absolute;
        background-color: $white;

        @include animation(background-color);

        width: calc(100% + 1rem);
        left: -0.5rem;
        height: 1px;
        bottom: calc(50% - 1px);
        z-index: -1;
    }
}
