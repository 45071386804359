@use 'sass:math';
@use 'sass:map';

@mixin card-max-width($max-width) {
    $max-width: map.get($max-width, 'default');

    max-width: $max-width;
}

@mixin card() {
    margin: 0 auto;
    background-color: $white;
    padding: 1.5rem;
    border-radius: $border-radius;
    border: 1px solid $gray-200;
    position: relative;

    @include shadow-1();

    .title {
        h3 {
            position: relative;
            z-index: 10;
        }
    }
}
