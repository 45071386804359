textarea {
    display: block;

    @include form-text($input-height, $input-font-size, $input-padding-x);

    height: 7rem;
    resize: none;

    + label {
        @include form-label($input-height, $input-font-size, $input-padding-x);
    }

    @each $name, $color in $theme-colors {
        &.textarea-#{$name}:not(.fc-readonly) {
            @include form-text-variant($color);
        }
    }

    &.fc-disabled {
        + label {
            @include form-label-disabled();
        }

        @include form-disabled();
    }

    &.fc-readonly {
        + label {
            @include form-label-readonly();
        }

        @include form-readonly();
    }
}
