@use 'sass:math';

button,
.btn {
    position: relative;

    @include button($button-height, $button-padding-x);

    &.btn-sm {
        @include button($button-height * 0.75, $button-padding-x * 0.75);
    }

    &.btn-xs {
        @include button($button-height * 0.625, $button-padding-x * 0.5);

        font-size: $button-font-size * 0.875;
    }

    @each $name, $colors in $button-colors {
        &.btn-#{$name} {
            @include button-variant($colors);
        }
    }

    &.btn-disabled {
        opacity: 0.8;
        pointer-events: none;
    }

    &.btn-block {
        display: block;
        min-width: 0;
        width: 100%;
    }

    &.btn-auto {
        width: auto;
    }
}

button {
    appearance: none;
}
