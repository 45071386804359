@use 'sass:math';

@mixin form-text($height, $font-size, $padding-x) {
    border-width: $input-border-width;
    border-style: solid;
    border-color: $input-border-color;
    border-radius: $input-radius;
    line-height: $input-lh;
    padding: math.div($height, 2) - math.div($input-lh, 2) - $input-border-width $padding-x math.div($height, 2) - math.div($input-lh, 2) - $input-border-width $padding-x;
    font-size: $input-font-size;
    color: $input-color;
    width: $input-width;
    max-width: 100%;
    height: $height;
    outline: none;
    z-index: 10;
    position: relative;
    background-color: $white;

    @include animation(border-color, background-color, color, z-index);

    &:not(.fc-disabled).unstyled {
        border-color: $white;
    }

    &:not(.fc-readonly).unstyled {
        border-color: $white;
    }
}

@mixin form-text-variant($color) {
    &.fc-filled {
        + label {
            color: $input-label-color;
        }
    }

    &:focus {
        /* stylelint-disable-next-line declaration-no-important */
        z-index: 20 !important;
        border-color: $color;

        &:not(.label-block) {
            + label {
                color: $color;
            }
        }
    }

    &.fc-touched.fc-invalid {
        /* stylelint-disable-next-line declaration-no-important */
        z-index: 11 !important;
        border-color: $danger;

        &:not(.label-block) {
            + label {
                color: $danger;
            }
        }
    }
}

@mixin form-label($height, $font-size, $padding-x) {
    top: 0;
    position: absolute;
    font-size: $input-font-size;
    line-height: $input-lh;
    left: $padding-x + 0.1875rem;
    transform: translate(0, math.div($height, 2) - math.div($input-lh, 2));
    z-index: 30;
    white-space: nowrap;
    max-width: calc(100% - $padding-x * 2);
    text-overflow: ellipsis;
    overflow: hidden;
    /* stylelint-disable-next-line declaration-no-important */
    color: $black !important;
    opacity: 0.3;

    @include animation(color, transform, font-size);
    @include noselect();

    cursor: text;

    &::before {
        content: '';
        display: block;
        position: absolute;
        background-color: $white;

        @include animation(background-color);

        width: calc(100% + 1rem);
        left: -0.5rem;
        height: 1px;
        bottom: calc(50% - 1px);
        z-index: -1;
    }
}

@mixin form-label-disabled() {
    color: $gray-300;

    &::before {
        background-color: $gray-100;
    }
}

@mixin form-label-disabled-filled() {
    color: $gray-200;

    &::before {
        background-color: $gray-300;
    }
}

@mixin form-dropdown($input-height, $input-padding-x) {
    border-width: $input-border-width;
    border-style: solid;
    border-color: $input-border-color;
    border-radius: $input-radius;
    width: $input-width;
    max-width: 100%;

    input {
        border-width: 0;

        + .icon-caret {
            position: absolute;
            z-index: 10;
            top: math.div(($input-height - 1.5rem), 2);
            right: 1rem;
            cursor: pointer;
        }
    }
}

@mixin form-disabled {
    color: $gray-400;
    background-color: $gray-100;
}

@mixin form-readonly() {
    color: $gray-400;
    background-color: $gray-100;
}

@mixin form-label-readonly() {
    color: $gray-400;

    &::before {
        background-color: $gray-100;
    }
}

@mixin form-dropdown-list($height, $font-size, $padding-x) {
    border-width: $input-border-width;
    border-top-width: 0;
    border-style: solid;
    border-color: $white $input-border-color $input-border-color $input-border-color;
    width: calc(100% + $input-border-width * 2);
    left: $input-border-width * -1;
    background-color: $white;
    border-bottom-left-radius: $input-radius;
    border-bottom-right-radius: $input-radius;
    top: calc(100% - 0.125rem);
    overflow: scroll;

    ul {
        margin: 0;
        padding: 0 0 0.5rem;
        list-style: none;

        li {
            color: $gray-500;
            padding: (math.div($height, 4) - math.div($input-lh, 4) - $input-border-width) $padding-x (math.div($height, 4) - math.div($input-lh, 4) - $input-border-width) $padding-x;
            font-size: $input-font-size;
            cursor: pointer;

            &:hover {
                color: $gray-600;
                background-color: $gray-100;
            }
        }
    }
}

@mixin form-dropdown-variant($color) {
    &.fc-filled {
        > label {
            color: $input-label-color;
        }
    }

    &.dropdown-open {
        border-color: $color;

        > label {
            color: $color;
        }

        .dropdown-list {
            border-color: $color;
        }
    }

    &.fc-touched.fc-invalid {
        z-index: 11;
        border-color: $danger;

        > label {
            color: $danger;
        }

        .dropdown-list {
            border-color: $danger;
        }
    }
}

@mixin form-calendar-variant($color) {
    &.fc-filled {
        > label {
            color: $input-label-color;
        }
    }

    &.fc-touched.fc-invalid {
        input {
            border-color: $danger;
        }

        > label {
            color: $danger;
        }
    }
}

@mixin form-timer-variant($color) {
    &.fc-filled {
        > label {
            color: $input-label-color;
        }
    }

    &.fc-touched.fc-invalid {
        input {
            border-color: $danger;
        }

        > label {
            color: $danger;
        }
    }
}
