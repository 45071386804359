/* Font Size */
$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.875;
$font-size-xs: $font-size-base * 0.75;

$h1-font-size: $font-size-base * 4; // 64
$h2-font-size: $font-size-base * 3.5; // 56
$h3-font-size: $font-size-base * 3; // 48
$h4-font-size: $font-size-base * 2.5; // 40;
$h5-font-size: $font-size-base * 2; // 32
$h6-font-size: $font-size-base * 1.5; // 24;

$font-sizes: (
    64: $h1-font-size,
    56: $h2-font-size,
    48: $h3-font-size,
    40: $h4-font-size,
    32: $h5-font-size,
    24: $h6-font-size,
    22: $font-size-base * 1.375,
    20: $font-size-base * 1.25,
    18: $font-size-base * 1.125,
    16: $font-size-base,
    14: $font-size-sm,
    13: $font-size-base * 0.8125,
    12: $font-size-xs,
);

// colors
$theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark-400,
    'gray-100': $gray-100,
    'gray-200': $gray-200,
    'gray-300': $gray-300,
    'gray-400': $gray-400,
    'gray-500': $gray-500,
    'gray-600': $gray-600,
    'gray-700': $gray-700,
    'white': $white,
    'balck': $black,
);

// spacers
$spacer: 1rem;
$spacers: (
    0: 0,
    1: (
        $spacer * 0.125,
    ),
    2: (
        $spacer * 0.25,
    ),
    3: (
        $spacer * 0.375,
    ),
    4: (
        $spacer * 0.5,
    ),
    5: (
        $spacer * 0.625,
    ),
    6: (
        $spacer * 0.75,
    ),
    7: (
        $spacer * 0.875,
    ),
    8: (
        $spacer,
    ),
    9: (
        $spacer * 1.125,
    ),
    10: (
        $spacer * 1.25,
    ),
    11: (
        $spacer * 1.375,
    ),
    12: (
        $spacer * 1.5,
    ),
    13: (
        $spacer * 1.75,
    ),
    14: (
        $spacer * 2,
    ),
    15: (
        $spacer * 2.375,
    ),
    16: (
        $spacer * 2.675,
    ),
    17: (
        $spacer * 3,
    ),
    18: (
        $spacer * 4,
    ),
    19: (
        $spacer * 5,
    ),
);

$grid-gutter-width: 1.5rem;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1248px,
);

$border-radius: 0.1875rem;

$input-border-width: 0.125rem;
$input-border-color: $gray-300;
$input-font-size: 0.8125rem;
$input-label-font-size: 0.75rem;
$input-label-color: $gray-400;
$input-color: $gray-600;
$input-height: 3.25rem;
$input-padding-x: 1.25rem;
$input-lh: 1rem;
$input-radius: 0.375rem;
$input-width: 28rem;

$button-border-width: $input-border-width;
$button-border-color: $input-border-color;
$button-font-size: $input-font-size;
$button-font-weight: 700;
$button-color: $white;
$button-height: $input-height;
$button-padding-x: 2.75rem;
$button-lh: $input-lh;
$button-radius: $input-radius;
$button-width: $input-width;

$button-colors: (
    'primary': (
        'default': $primary,
    ),
    'secondary': (
        'default': $secondary,
    ),
    'success': (
        'default': $success,
    ),
    'warning': (
        'default': $warning,
    ),
    'info': (
        'default': $info,
    ),
    'danger': (
        'default': $danger,
    ),
    'muted': (
        'default': $gray-300,
    ),
    'dark': (
        'default': $dark-400,
        'hover': $dark-500,
    ),
);

$table-colors: (
    'primary': (
        'default': $primary,
    ),
    'secondary': (
        'default': $secondary,
    ),
);
$frame-max-width: (
    'full': (
        'default': 100%,
    ),
    'lg': (
        'default': 1400px,
    ),
    'md': (
        'default': 800px,
    ),
    'sm': (
        'default': 400px,
    ),
);
$card-max-width: (
    'full': (
        'default': 100%,
    ),
    'lg': (
        'default': 1400px,
    ),
    'md': (
        'default': 800px,
    ),
    'sm': (
        'default': 400px,
    ),
);
$table-font-size: 0.75rem;
$table-header-font-size: 0.8125rem;
$table-frame-width: 100%;
$table-frame-margin: 0 auto;

$icon-sizes: (
    1: 0.25rem,
    2: 0.5rem,
    3: 0.75rem,
    4: 0.875rem,
    5: 1rem,
    6: 1.25rem,
    7: 1.5rem,
    8: 1.75rem,
    9: 2rem,
    10: 2.25rem,
    11: 2.5rem,
    12: 2.75rem,
);

$animation-time: 0.15s;
$animation-style: ease-in-out;

$switch-width: 2.5rem;
$switch-height: 1.25rem;

$menu-dropdown-z-index: 500;
$menu-dropdown-padding-x: 1.5rem;
$menu-dropdown-padding-y: 0.5rem;
$menu-dropdown-font-size: 0.8125rem;
$menu-dropdown-background-color: $white;
$menu-dropdown-color: $black;
$menu-dropdown-color-hover: $primary;
$menu-dropdown-line-height: 1rem;
