input.input {
    &[type='text'],
    &[type='email'],
    &[type='date'],
    &[type='password'],
    &[type='number'] {
        @include form-text($input-height, $input-font-size, $input-padding-x);

        &::placeholder {
            color: #0000004d;
        }

        &.input-sm {
            @include form-text($input-height * 0.75, $input-font-size, $input-padding-x * 0.75);
        }

        + label {
            @include form-label($input-height, $input-label-font-size, $input-padding-x);
        }

        &.label-block {
            + label {
                &::before {
                    display: none;
                }
            }
        }

        @each $name, $color in $theme-colors {
            &.input-#{$name}:not(.fc-readonly) {
                @include form-text-variant($color);
            }
        }

        &.fc-disabled {
            + label {
                @include form-label-disabled();
            }

            @include form-disabled();
        }

        &.fc-readonly {
            + label {
                @include form-label-readonly();
            }

            @include form-readonly();
        }

        &:-webkit-autofill {
            + label {
                /* stylelint-disable-next-line declaration-no-important */
                display: none !important;
            }
        }
    }
}
