$svg: 0.6875rem;

.radio {
    @include form-choice();

    @each $name, $color in $theme-colors {
        &.input-#{$name}:not(.fc-readonly) {
            @include form-text-variant($color);
        }
    }

    input + label {
        .icon {
            top: 0;
            left: 0.21875rem;

            svg {
                min-width: $svg;
                min-height: $svg;
                width: $svg;
                height: $svg;
            }
        }

        &::before {
            border-radius: 100%;
        }
    }
}
