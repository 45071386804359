.close {
    position: relative;
    width: 1rem;
    height: 1rem;

    &::after,
    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 1rem;
        height: 0.125rem;
        top: 50%;
        left: 50%;
        background-color: $dark-400;
    }

    &::after {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &::before {
        transform: translate(-50%, -50%) rotate(135deg);
    }
}
