@use 'sass:math';

.fc-timer {
    display: inline-block;
    position: relative;
    z-index: 100;
    outline: none;
    cursor: pointer;
    width: 100%;
    max-width: 100%;

    &.timer-open {
        cursor: default;
        z-index: 105;
    }

    @include noselect();

    input {
        @include form-text($input-height, $input-font-size, $input-padding-x);

        cursor: pointer;
        opacity: 1;
    }

    > label {
        @include form-label($input-height, $input-font-size, $input-padding-x);

        cursor: pointer;
    }

    @each $name, $color in $theme-colors {
        &.timer-#{$name} {
            @include form-timer-variant($color);
        }
    }

    &.fc-disabled {
        > label {
            @include form-label-disabled();
        }

        input {
            @include form-disabled();
        }
    }

    > .icon {
        position: absolute;
        z-index: 20;
        top: math.div(($input-height - 1.5rem), 2);
        left: 1rem;
    }

    @include media-breakpoint-up(lg) {
        z-index: 10;

        &.timer-open {
            z-index: 20;
        }
    }
}

.timer-picker {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 30;
    min-width: 100%;
    width: 20rem;
    background-color: $white;
    border: 1px solid $gray-300;
    padding-top: 3rem;

    div {
        .hours,
        .minutes {
            max-height: 18rem;
            overflow: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            div {
                display: flex;
                justify-content: center;
                line-height: 1;
                background-color: $white;
                text-align: center;
                color: $gray-500;
                border-radius: $border-radius;

                @include animation(background-color, border-color, color);

                &.selected {
                    border-color: $secondary;
                    background-color: $secondary;
                    color: $white;
                }

                &:focus {
                    border-color: $secondary;
                    background-color: rgba($color: $secondary, $alpha: 80%);
                    color: $white;
                }

                @media (hover: hover) {
                    &:hover {
                        border-color: $secondary;
                        background-color: rgba($color: $secondary, $alpha: 80%);
                        color: $white;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 0;
        position: absolute;
        bottom: auto;
    }
}
