$svg: 0.75rem;

.checkbox {
    @include form-choice();

    @each $name, $color in $theme-colors {
        &.input-#{$name}:not(.fc-readonly) {
            @include form-text-variant($color);
        }
    }

    input + label {
        .icon {
            top: 0.0625rem;
            left: 0.1875rem;

            svg {
                min-width: $svg;
                min-height: $svg;
                width: $svg;
                height: $svg;
            }
        }

        &::before {
            border-radius: $input-radius;
        }
    }
}
