@use 'sass:math';

.dropdown {
    display: inline-block;
    position: relative;
    z-index: 10;

    input {
        @include form-text($input-height - 0.25rem, $input-font-size, $input-padding-x);

        cursor: pointer;
    }

    > label {
        @include form-label($input-height - 0.25rem, $input-font-size, $input-padding-x);

        left: 1.25rem;
    }

    @include form-dropdown($input-height, $input-padding-x);

    .dropdown-list {
        position: absolute;
    }

    @each $name, $color in $theme-colors {
        &.dropdown-#{$name} {
            @include form-dropdown-variant($color);
        }
    }

    &.fc-disabled {
        > label {
            @include form-label-disabled();
        }

        input {
            @include form-disabled();

            cursor: default;
        }
    }

    &.dropdown-open {
        /* stylelint-disable-next-line declaration-no-important */
        z-index: 35 !important;
        border-radius: $input-radius $input-radius 0 0 !important;

        .dropdown-list {
            @include form-dropdown-list($input-height, $input-font-size, $input-padding-x);
            /* stylelint-disable-next-line declaration-no-important */
            z-index: 40 !important;
        }
    }

    &.dropdown-disabled {
        padding-right: 0;
    }
}
