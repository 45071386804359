@mixin link-unstyle {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

@mixin list-unstyle {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}
