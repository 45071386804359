.icon-avatar {
    max-width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 100%;

    &::after {
        content: '';
        display: block;
    }

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        display: block;
        object-fit: cover;
    }
}
